.form-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 400px;
  height: auto;
  margin: auto;
}
.blocco_page_container {
  display: flex;
  flex-direction: column;
  width: 75%;
  max-width: 500px;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
}
.page_login {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
}
.logo_container {
  display: flex;
  width: 200px;
  height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  border-radius: 50%;
  border: 1px solid #c6c6c6;
  overflow: hidden;
}
.logo_in_form {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}
.input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 20px 0;
}
.spazio_input {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  padding: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 25px auto;
}
.campo_input {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  padding: 5px 10px;
  font-size: 18px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  text-indent: 10px;
  border: none;

  border-bottom: 2px solid #c6c6c6;
}
.campo_label {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  top: -15px;
  width: auto;
  height: auto;
  transition: all ease-in 0.3s;
  padding: 0 5px;
  box-sizing: border-box;
  color: #989898;
}
.campo_input:focus {
  outline: none;
  border-color: #184491;
}
.campo_input:focus-within .spazio_input p {
  display: none;
}
.spazio_input:focus-within .campo_label {
  top: -18px;
  bottom: auto;
  height: 15px;
  width: fit-content;
  background-color: #ffffff;
  color: #184491;
}
.spazio_input:focus-within .campo_label {
  top: -15px;
  bottom: auto;
  height: 15px;
  width: fit-content;
  background-color: #ffffff;
}
.spazio_input p {
  position: absolute;
  top: 0;
  right: 5px;
  width: auto;
  height: 100%;
  color: #8a1002;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .form-container {
    width: 90%;
  }
}
