.container_topbar {
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: transparent;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  bottom: 60px;
  right: 5px;
  z-index: 1000;
}
.gruppo_icone_top {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
}
.gruppo_left {
  margin-left: 34px;
}
.menu_topbar {
  display: flex;
  width: 50px;
  height: 50px;
  flex-direction: column;
  font-size: 0.9em;
  margin: 2px 5px;
  padding: 5px;
  align-content: center;
  justify-content: center;
  color: #ffffff;
  text-decoration: none;
  background-color: #1c5b96;
  border-radius: 50%;
  box-shadow:  0 0 4px #5d5d5d;
}
.menu_topbar label {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 25px;
  align-items: center;
  justify-content: center;
  font-variant: small-caps;
}
.menu-topbar .material-symbols-rounded {
  display: flex;
  width: auto;
  flex-direction: row;
  margin: 0 auto;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.send_order {
  width: 50px;
  background-color: #ffffff;
}
.top_menu_buttons {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
.active {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
}
.send-btn{
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  margin: 0 auto;
}

@media all and (max-width: 768px) {
  .menu-topbar {
    flex-direction: column;
    font-size: 0.8em;
    margin: auto 0;
    justify-content: center;
  }
  .menu-topbar label {
    font-size: 13px;
    text-align: center;
  }
  .horizontal {
    flex-direction: row;
  }
  .top_menu_buttons {
    display: flex;
    flex-direction: row;
  }
  .menu-topbar .material-symbols-rounded{
    width: auto;
  }
}
