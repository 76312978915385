.container-fornoinfo {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content:  center;
  flex-wrap: wrap;
  margin: 20px auto;
}
.container-fuorinfo {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin: 10px auto;
}
.container-ing {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
}
.ingr-fuori {
  display: flex;
  flex-direction: row;
  width: 32%;
  height: auto;
  margin: 2px auto;
  padding: 6px 10px;
  box-sizing: border-box;
  border-radius: 2px;
  border: .5px solid #8a8a8a;
}
.field textarea {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  min-height: 90px;
  resize: none;
  margin: 0 auto;
}
.checked{
  border-color: #1872a3;
}

@media screen and (max-width:767px) {
  .container-ing{
    flex-direction: column;
  }
  .ingr-fuori{
    width: 100%;
  }
}