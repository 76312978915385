.card {
  display: flex;
  flex-direction: column;
  width: 21%;
  height: auto;
  margin: 10px 1% 30px 1%;
  min-width: 250px;
  border-bottom: 1px solid #d7d7d7;
  border-width: 1px;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
}
.card a {
  color: #2c2c2c;
  text-decoration: none;
}
.titolo {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  padding: 5px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 1.5vw;
  margin-bottom: 9px;
  font-weight: bold;
  height: 50px;
  font-variant: small-caps;
  font-size: clamp(1rem, 0.9486rem + 0.2162vw, 1.3rem);
}
.dati_orizzontali {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
}
.card .image {
  height: 230px;
  width: 100%;
  object-fit: cover;
  margin: 0 auto;
}
.ingredienti {
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 100%;
  margin: 0 5px;
}
.it {
  font-size: 1em;
  font-style: italic;
  margin-top: 4px;
  margin-bottom: 6px;
  display: block;
  height: auto;
  width: 98%;
  margin: 0 auto;
  word-wrap: break-word;
  flex-wrap: wrap;
}
.en {
  font-size: 0.7em;
  font-style: italic;
  margin-top: 4px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  width: 98%;
  margin: 0 auto;
  height: auto;
  word-wrap: break-word;
}
.prezzi {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  font-family: 'Raleway', sans-serif;
}
.prezzo {
  display: flex;
  flex-direction: row;
  width: auto;
  margin: 3px auto;
  font-size: 1.5em;
  align-items: center;
  justify-content: center;
}

.pulsanti {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 50%;
  padding: 5px 10px;
  box-sizing: border-box;
}
.conenitore-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: auto;
}
.qty {
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .card {
    width: 100%;
  }
  .card .image {
    height: 400px;
  }
  .card .titolo {
    font-size: 4vw;
  }
}
