.Tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.nav-tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.outlet {
  margin-top: 20px;
}

.titolo-tab {
  margin-bottom: 20px;
}
.content-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
