.container-ordini{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}

.contenuto-ordini{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.apritavolo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    align-items: center;

}


 