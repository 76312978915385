.conteiner-antipasti {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.piatti {
  display: flex;
  flex-direction: column;
}

.container_box{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  
}