.nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-height: 100vh;
  position: fixed;
  background-color: #ffffff;
  top: 0px;
  font-weight: bold;
  overflow: visible;
  z-index: 999;
  transition: all ease-in-out 0.4s;
}
.menu_icon_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1000;
  align-items: center;
  justify-content: flex-end;
}
.menu_icon {
  display: flex;
  flex-direction: column;
  width: 36px;
  height: 36px;
  position: fixed;
  padding: 5px;
  box-sizing: border-box;
  top: 5px;
  left: 3px;
  z-index: 1001 !important;
  overflow: hidden;
  cursor: pointer;
  align-items: flex-start;
  justify-content: flex-start;
}
.menu_icon .material-symbols-rounded {
  display: flex;
  flex-direction: column;
  width: 36px;
  height: 36px;
  font-size: 36px;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all ease-in-out 0.3s;
}
/* .menu_icon span{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2.3px;
  background-color: #ffffff;
  margin-top: 6px;
  border-radius: 3px;
}
.menu_icon span:first-child{
  margin-top: 2px;
} */
.menu_icon_container img {
  width: 110px;
  margin: 5px 0 10px 55px;
  padding: 10px 10px 0 0;
  box-sizing: border-box;
}
.nav h2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}
.content-nav {
  display: block;
  flex-direction: row;
  top: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: none;
  border-bottom: none;
  transition: all ease-in-out 0.4s;
  z-index: 10001;
  background-color: #fff;
  width: 100%;
}
.content-nav::-webkit-scrollbar {
  display: none;
}
.content-nav a {
  display: flex;
  flex-direction: row;
  width: 140px;
  height: 120px;
  line-height: 1.5em;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: rgba(#fff, 0.35);
  color: rgb(84, 94, 111);
  padding: 11px 10px;
  font-variant: small-caps;
  font-size: 0.75em;
  margin: 10px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: .5px solid #9e9e9e;
}
.content-nav.active a {
  color: white;
}
.content-nav:not(.active)::after {
  opacity: 0.2;
}
.content-nav:not(.active):hover a {
  color: rgba(#fff, 0.75);
}
.content-nav-block{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.line {
  border-bottom: 1px solid #9e9e9e;
}
.logout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 5px;
  box-sizing: border-box;
  color: #7600dc;
}
@media screen and (max-width: 768px) {
  .menu_icon {
    top: 12px;
  }
  .content-nav {
    flex-direction: row;
    width: 100%;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .content-nav a{
    width: 43%;
    margin: 5px auto;
  }
}
