.card_personalizzami {
  display: flex;
  flex-direction: column;
  width: 21%;
  height: auto;
  margin: 10px 1% 30px 1%;
  min-width: 250px;
  border-width: 1px;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
}
.titolo_personalizzami {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 2vw;
  margin-bottom: 9px;
  font-weight: bold;
  height: 50px;
  font-variant: small-caps;
  width: 100%;
  font-size: clamp(1rem, 0.9486rem + 0.2162vw, 1.3rem);
}
.image_personalizzami {
  height: 200px;
  object-fit: cover;
}

.pulsanti_personalizzami {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 50%;
  padding: 5px 10px;
  box-sizing: border-box;
}

.conenitore-info-personalizzami {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin-top: 10px;
}
.card-personalizzami .image_personalizzami {
  height: 230px;
  width: 100%;
  object-fit: cover;
  margin: 0 auto;
}

.pulsanti_personalizzami {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 50%;
  padding: 5px 10px;
  box-sizing: border-box;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(96, 94, 94);
  margin: 1em 0;
  padding: 0;
}
.vertical{
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .card_personalizzami {
    width: 100%;
    min-width: 100%;
  }
  .card_personalizzami .image_personalizzami {
    height: 400px;
  }
  .card_personalizzami .titolo_personalizzami {
    font-size: 4vw;
  }
}
