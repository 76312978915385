.select{
   display: flex;
   flex-direction: row;
   width: 100%;
   height: auto;
   align-items: center;
   justify-content: flex-start;
   padding: 5px 10px;
   font-size: 20px;
   padding: 5px 10px;
   box-sizing: border-box;
   border-radius: 10px;
}