.container-piatti {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.container-piatto {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90%;
  max-width: 500px;
  margin: 30px auto;
}
.grande {
  width: 100%;
}
.container-ruoli {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin: 5px 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.container-categorie {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
}
.margin50{
  margin-top: 60px;
}
.contenittore_bottoni{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

}
@media screen and (max-width: 768px){
  .margin50{
    margin-top: 30px;
  }
}