

.container-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.container-storico {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 50px 0 0 0;
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;
}
table {
  width: 100%;
  height: auto;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
}
thead {
  width: 100%;
  border-style: solid;
  margin: 0;
  padding: 0;
  border: none;
  background-color: #1b4389;
  color: #ffffff;
}
td:first-child {
  text-align: center;
}
th,
td {
 
  padding: 10px;
  border-bottom: solid;
  border-width: 0.1rem;
  border-color: rgb(183, 211, 255);
  box-sizing: border-box;
  min-width: 110px;
}
tbody {
  width: 100%;
  height: auto;
}
tr {
  width: 100%;
  height: auto;
}
td {
  width: auto;
  font-weight: bold;
  text-transform: uppercase;
}
td .func_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
td label {
  width: 150px;
  height: auto;
  padding: 12px 5px;
  background-color: #4e5c75;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  display: none;
}
td span{
  display: flex;
  flex-direction: row;
  width: auto;
  padding: 12px 5px;
}
.func_container  {
  min-width: 30px;
  height: 34px;
  align-items: center;
  justify-content: center;
}
.func_container .qty {
  display: flex;
  flex-direction: column;
  min-width: 30px;
  height: 34px;
  align-items: center;
  justify-content: center;
}
td:last-child, th:last-child{
  width: 100px;
}
.contenuto{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.contenuto-vuoto{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
justify-content: center;}



@media all and (max-width: 768px) {
  table{
    width: 100%;
    font-size: 14px;
  }
  td .func_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .func_container .qty {
    min-width: 30px;
    height: 34px;
    align-items: center;
    justify-content: center;
  }
  thead{
    display: none;
  }
  tbody{
    width: 100%;
  }
  tr{
    width: 100%;
    padding: 0;
    margin: 0;
    border-bottom: 2px solid #394457;
    box-sizing: border-box;
  }
  td{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ededed;
    padding: 0;
  }
  td label{
    display: flex;
    margin: 0;
    width: 45%;
    box-sizing: border-box;
  }
  td span{
    width: 55%;
    margin: 0;
    
    box-sizing: border-box;
  }
  td:last-child, th:last-child{
    width: 100%;
    text-align: center;
    justify-content: end;
  }
 
}
