.choice_container {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  margin-top: 20px;

}
.container-stepper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
}
.progressbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 12px;
  background-color: #5e5e5e;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 50px;
}
.progressbar div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(37, 146, 17);
}
.titolo-page {
  display: flex;
  width: 400px;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 24px;
  margin-bottom: 10px;
}
.ingredienti_setter {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 50vh;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  overflow-y: scroll;
}
.ingredienti_setter .ingredienti {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
}
.pizze {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  justify-content: center;
}
.selectpizze {
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 70%;
  height: auto;
  margin: 10px auto;
  align-items: center;
  justify-content: center;
}
.note_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.note_container textarea {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 120px;
  align-items: flex-start;
  justify-content: flex-start;
  resize: none;
}

.pizza-btn-container {
  display: flex;
  flex-direction: row;
  width: 500px;
  height: auto;
  align-items: center;
  justify-content: space-evenly;
}
.pizza-btn-container .btn{
    margin: 0;
}