.page_apri_tavolo {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 400px;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: auto;
}


.email-suggestions {
  list-style: none;
  padding: 0;
  margin: 0;
}

.email-suggestions li {
  color: #666;
  font-size: 14px;
}