.mybutton {
  margin-left: 5px;
  margin-right: 5px;
}
.small-icon {
  font-size: 22px;
}

.large-icon {
  font-size: 30px;
}

.material-symbols-outlined {
  cursor: pointer;
}

.material-symbols-outlined[title]:hover::after {
    content: attr(title); 
    position: absolute;
    background-color: #000;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%; 
    opacity: 0; 
    pointer-events: none; 
    transition: opacity 0.1s ease-in-out; 
  }
  
  .material-symbols-outlined[title]:hover::after {
    opacity: 1;
  }
  