.container-portate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
}
.container-ordini {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
}
.container-ordini .apritavolo {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: auto;
  padding: 10px 0;
  margin: 0;
  margin-top: 0;
}
.container-ordini {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  margin: 5px 0;
  box-sizing: border-box;
}
.nume-page{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}