a{
  text-decoration: none;
}
.container-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100vh;
}
.subcontainer-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.corpo {
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 5px;
  bottom: 0;
  width: 100vw;
  height: auto;
  min-height: auto;
  transition: all ease-in-out 0.4s;
  overflow-y: scroll;
  z-index: 800;
}
@media all and (max-width: 800px) {
  .corpo {
    display: flex;
    flex-direction: column;
    width: 100vw;
    left: 0;
    top: 35px;
    margin: 0;
    padding: 0;
  }
}
