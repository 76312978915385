.prodotti-carrello {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin: 10px;
  border-width: 1px;
  padding: 10px;
  box-sizing: border-box;
}
.prodotti-carrello a {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.prodotti-carrello .titolo {
  font-size: 1.7vw;
  margin-bottom: 9px;
  font-weight: bold;
}
.dati_orizzontali {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
}
.image {
  height: 200px;
  width: 98%;
  object-fit: cover;
  margin: 0 auto;
}
.ingredienti {
  display: flex;
  flex-direction: column;
  width: auto;
  width: 100%;
  margin: 10px 5px;
  text-align: center;
  color: rgb(76, 76, 76);
  font-weight: 600;
}
.it {
  font-size: 1em;
  font-style: italic;
  margin-top: 4px;
  margin-bottom: 6px;
  display: block;
  height: auto;
  width: 98%;
  margin: 0 auto;
  word-wrap: break-word;
  flex-wrap: wrap;
}
.en {
  font-size: 0.7em;
  font-style: italic;
  margin-top: 4px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  width: 98%;
  margin: 0 auto;
  height: auto;
  word-wrap: break-word;
}
.prezzo {
  display: flex;
  width: auto;
  margin: 3px auto;
  font-size: 1.5em;
  align-items: center;
  justify-content: center;
}
.contenitore-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.totale-pizza {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: auto;
  margin: 10px auto;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .prodotti-carrello .titolo{
    font-size: 6vw;
  }
}